// Большие девайсы (большие десктопы, < 1200px)
@media (max-width: 1199.98px) {

  .main_screen > .container:after,
  .advantages > .container:after,
  .free_calculation > .container:after,
  .work_examples > .container:after,
  .headsets_types > .container:after,
  .when_order > .container:after,
  .compare > .container:after,
  .calculator > .container:after,
  .our_work > .container:after,
  .personal_offer > .container:after,
  .certificate > .container:after,
  .partners > .container:after,
  .vertical_right,
  .vertical_left {
    display: none;
  }

  //HEADER

  header ul li {
    margin-right: 18px;
  }

  header .phone_col {
    padding-left: 0;
  }

  header .phone_col span {
    font-size: 14px;
  }

  //MAIN SCREEN

  .main_screen .col_right {
    padding-right: 15px;
  }

  .main_screen h1 {
    font-size: 46px;
    line-height: 54px;
  }

  //ADVANTAGES

  .advantages {
    padding-bottom: 70px;
  }

  .advantages h2 {
    margin-bottom: 60px;
    font-size: 46px;
    line-height: 54px;
  }

  //FREE CALCULATION

  .free_calculation h2 {
    font-size: 46px;
    line-height: 54px;
  }

  //WORK EXAMPLES

  .work_examples h2 {
    font-size: 46px;
    line-height: 54px;
  }

  .work_examples p:last-of-type {
    margin-bottom: 60px;
  }

  .work_examples .example_price p:last-of-type {
    margin-bottom: 20px;
  }

  .work_examples > .container {
    padding-bottom: 70px;
  }

  .examples_wrap {
    justify-content: space-around;
  }

  //INDIVIDUAL PROJECT

  .in_ind_project p {
    padding-right: 0;
    background: none;
  }

  //HEADSETS TYPES

  .headsets_types h2 {
    font-size: 46px;
    line-height: 54px;
  }

  .headsets_types .headsets_types_wrap {
    justify-content: space-around;
  }

  //WHEN ORDER

  .when_order h2 {
    margin-bottom: 60px;
    font-size: 46px;
    line-height: 54px;
  }

  .when_order_desc {
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .when_order_ico img {
    display: none;
  }

  //COMPARE

  .compare > .container {
    padding-top: 90px;
  }

  .compare h2 {
    margin-bottom: 60px;
    font-size: 46px;
    line-height: 54px;
  }

  //CALCULATOR

  .calculator > .container {
    padding-top: 90px;
  }

  .calculator h2 {
    margin-bottom: 60px;
    font-size: 46px;
    line-height: 54px;
    text-align: center;
  }

  .calculator .img_wrap {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .calculator form {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .calculator form .col_right {
    padding-right: 45px;
  }

  .calculator form .col_right input[type="text"] {
    margin-left: auto;
  }

  .calculator form .col_right input[type="tel"] {
    margin-left: auto;
  }

  .calculator form .col_right button[type="submit"] {
    margin-left: auto;
  }

  //OUR WORK

  .our_work h2 {
    font-size: 46px;
    line-height: 54px;
    margin-bottom: 60px;
  }

  //CERTIFICATE

  .certificate > .container {
    padding-bottom: 70px;
  }

  .certificate h2 {
    font-size: 46px;
    line-height: 54px;
    margin-bottom: 60px;
  }

  .certificate_wrap {
    justify-content: space-around;
  }

  .certificate_wrap img {
    margin-bottom: 40px;
  }

  //PARTNERS

  .partners > .container {
    padding-top: 90px;
    padding-bottom: 30px;
  }

  .partners h2 {
    font-size: 46px;
    line-height: 54px;
  }

  .partners .partners_wrap {
    justify-content: space-around;
  }

  .partners .partner {
    margin-bottom: 40px;
  }

  //MANUFACTURE

  .manufacture > .container {
    padding-top: 90px;
  }

  .manufacture h2 {
    font-size: 46px;
    line-height: 54px;
  }

  .manufacture h5 {
    margin-bottom: 60px;
  }

  .manufacture .map_wrap {
    margin-top: 100px;
  }

  .manufacture .map_wrap .address {
    top: 160px;
  }

  //FOOTER

  footer ul li {
    margin-right: 18px;
  }

  footer .phone_col {
    padding-left: 0;
  }

  footer .phone_col span {
    font-size: 14px;
  }

  .footer_bottom_row {
    font-size: 14px;
  }

  .footer_bottom_row div:nth-of-type(2) a {
    font-size: 14px;
  }
}

// Средние девайсы («таблетки», < 992px)
@media (max-width: 991.98px) {

  //HEADER

  header .phone_col span {
    display: block;
  }

  .phone_col_wrap {
    display: flex;
    justify-content: flex-end;
    padding-right: 70px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  //MOBILE MENU

  .mobile_menu {
    position: fixed;
    z-index: 100;
    top: 88px;
    width: 100%;
    background-color:   rgba(32, 32, 32, 0.85);
  }

  .mobile_nav {
    display: none;
    text-align: center;

    ul {
      padding-left: 0;
      padding-top: 30px;
      padding-bottom: 10px;
      list-style: none;

      li {
        margin-bottom: 15px;

        a {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }

  .hamburger {
    position: absolute;
    right: 10px;
    top: 33px;
    margin-right: 10px;
    padding: 0;
    outline: none !important;
    border: none;
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: #f7b814;
    height: 3px;
    width: 32px;
  }

  .mobile_hamburger_wrap {
    padding-top: 10px;
    padding-bottom: 10px;

    span {
      margin-top: -6px;
      font-size: 16px;
      color: #ffffff;
    }
  }

  //MAIN SCREEN

  .main_screen .main_screen_desc {
    padding-left: 0;
    padding-right: 0;
  }

  .main_screen .main_screen_desc .item:nth-of-type(1),
  .main_screen .main_screen_desc .item:nth-of-type(2),
  .main_screen .main_screen_desc .item:nth-of-type(3),
  .main_screen .main_screen_desc .item:nth-of-type(4){
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    background-position: center top;
    text-align: center;
  }

  .main_screen h1 {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .main_screen h4 {
    margin-left: auto;
    margin-right: auto;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }

  .main_screen form {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  //ADVANTAGES

  .advantages {
    padding-top: 80px;
    background: none;
  }

  .advantages h2 {
    padding-left: 0;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .advantages .col_left {
    padding-left: 15px;
  }

  .advantages .col_right {
    padding-right: 15px;
  }

  //FREE CALCULATION

  .free_calculation h2 {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .free_calculation p {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }

  .free_calculation form input[type="text"],
  .free_calculation form button[type="submit"] {
    margin-left: auto;
    margin-right: auto;
  }

  .free_calculation form .chekbox_wrap {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  .free_calculation form .chekbox_wrap .checkbox_span {
    font-size: 16px;
  }

  .free_calculation form .chekbox_wrap .checkbox_span a {
    font-size: 16px;
  }

  .free_calculation .calculation_form_wrap {
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
  }

  //WORK EXAMPLES

  .work_examples > .container {
    padding-top: 80px;
  }

  .work_examples h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .work_examples p {
    padding-left: 0;
    padding-right: 0;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }

  //INDIVIDUAL PROJECT

  .individual_project_wrap {
    margin-left: auto;
    margin-right: auto;
  }

  .in_ind_project h3 {
    font-size: 30px;
  }

  //HEADSETS TYPES
  .headsets_types {
    background: none;
  }

  .headsets_types > .container {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .headsets_types h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  //WHEN ORDER

  .when_order {
    background: none;
  }

  .when_order > .container {
    padding-top: 80px;
    padding-bottom: 90px;
  }

  .when_order h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .when_order_left {
    max-width: 585px;
    margin-left: auto;
    margin-right: auto;
  }

  .when_order_desc {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .when_order_desc:before {
    top: -28px;
    left: 24px;
    border: 8px solid transparent;
    border-bottom: 12px solid #ffdd69;
  }

  //COMPARE

  .compare {
    background: none;
  }

  .compare h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .compare_left {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .compare_left p {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
  }

  .compare ul {
    margin-left: auto;
  }

  .compare ul li {
    margin-left: auto;
    margin-right: auto;
  }

  //CALCULATOR

  .calculator {
    background-size: auto;
  }

  .calculator h2 {
    font-size: 40px;
    line-height: 48px;
  }

  .calculator .img_wrap p {
    font-size: 22px;
    line-height: 28px;
  }

  //OUR WORK

  .our_work > .container {
    padding-top: 90px;
  }

  .our_work h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .our_work > .container > .row > div:nth-of-type(odd) .work_wrap,
  .our_work .work_wrap {
    margin-left: auto;
    margin-right: auto;
  }

  //CERTIFICATE

  .certificate {
    background: none;
  }

  .certificate > .container {
    padding-top: 90px;
  }

  .certificate h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  //PARTNERS

  .partners {
    background-size: auto;
  }

  .partners h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .partners h5 {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }

  .partners .partner:nth-child(even) {
    padding-top: 0;
  }

  .partners .partner p {
    text-align: center;
  }

  //MANUFACTURE

  .manufacture h2 {
    padding-left: 0;
    padding-right: 0;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .manufacture h5 {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }

  .manufacture .manufacture_img_wrap {
    margin-left: 0;
    margin-right: 0;
  }

  .manufacture .map_wrap .address {
    top: 100px;
  }

  //FOOTER

  footer .phone_col span {
    display: block;
  }

  .phone_col_wrap {
    display: flex;
    justify-content: flex-end;
  }

  footer ul {
    padding-top: 20px;
    justify-content: center;
  }

  footer .footer_mail {
    text-align: center;
  }

  footer .phone_col_wrap {
    padding-right: 15px;
  }

  //PRIVACY

  .privacy {
    padding-top: 150px;
  }

  .privacy ul {
    padding-left: 18px;
  }

}

// Малые девайсы («ландшафтные», < 768px)
@media (max-width: 767.98px) {

  //HEADER

  .main_logo img {
    max-width: 90px;
  }

  header .phone_col p {
    padding-left: 23px;
    font-size: 18px;
    background-size: 16px;
  }

  header .phone_col span {
    padding-left: 23px;

    &:first-of-type {
      display: none;
    }
  }

  header .phone_col span span {
    display: block !important;
  }

  .mobile_menu {
    top: 86px;
  }

  .hamburger {
    margin-right: 0;
  }

  .hamburger-box {
    width: 30px;
  }

  .mobile_hamburger_wrap {
    padding-top: 2px;
    padding-bottom: 4px;
  }

  .mobile_hamburger_wrap span {
    margin-top: -3px;
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 22px;
  }

  .hamburger--elastic .hamburger-inner::before {
    top: 7px;
  }

  .hamburger--elastic .hamburger-inner::after {
    top: 14px;
  }

  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -14px, 0) rotate(-270deg);
  }

  //MAIN SCREEN

  .main_screen > .container {
    padding-top: 66px;
  }

  .main_screen h1 {
    margin-bottom: 15px;
    font-size: 32px;
    line-height: 38px;
  }

  .main_screen h4 {
    margin-bottom: 42px;
    font-size: 20px;
    line-height: 24px;
  }

  .main_screen .main_screen_desc .item {
    font-size: 16px;
  }

  .main_screen form h3 {
    font-size: 22px;
    line-height: 26px;
  }

  //ADVANTAGES

  .advantages {
    padding-top: 71px;
  }

  .advantages h2 {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 38px;
  }

  .advantages .advantages_item_wrap {
    margin-left: auto;
    margin-right: auto;
  }

  .advantages .col_left:nth-of-type(2),
  .advantages .col_right:nth-of-type(4) {
    padding-top: 0;
  }

  .advantages .col_left,
  .advantages .col_right {
    margin-bottom: 30px;
  }

  .advantages .advantages_item_wrap h4 {
    font-size: 22px;
    line-height: 26px;
  }

  //FREE CALCULATION

  .free_calculation > .container {
    padding-top: 44px;
    padding-bottom: 46px;
  }

  .free_calculation h2 {
    margin-bottom: 17px;
    font-size: 32px;
    line-height: 38px;
  }

  .free_calculation p {
    font-size: 20px;
    line-height: 24px;
  }

  //WORK EXAMPLES

  .work_examples > .container {
    padding-top: 74px;
    padding-bottom: 50px;
  }

  .work_examples h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .work_examples p {
    font-size: 20px;
    line-height: 24px;
  }

  .work_examples p:last-of-type {
    margin-bottom: 44px;
  }

  //INDIVIDUAL PROJECT

  .individual_project {
    padding-top: 21px;
    padding-bottom: 80px;
  }

  .individual_project_wrap {
    max-width: 300px;
  }

  .in_ind_project h3 {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }

  .in_ind_project p {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  .in_ind_project {
    padding-top: 146px;
    padding-bottom: 7px;
  }

  .in_ind_project img {
    max-width: 215px;
    top: -188px;
    left: 34px;
  }

  //HEADSETS

  .headsets_types h2 {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 38px;
  }

  .headsets_types > .container {
    padding-top: 74px;
    padding-bottom: 52px;
  }

  .headsets_types .headsets_item {
    margin-bottom: 30px;
  }

  //WHEN ORDER

  .when_order h2 {
    margin-bottom: 42px;
    font-size: 32px;
    line-height: 38px;
  }

  .when_order > .container {
    padding-top: 73px;
    padding-bottom: 80px;
  }

  .when_order_desc {
    padding-top: 11px;
    padding-bottom: 12px;
  }

  //COMPARE

  .compare h2 {
    margin-bottom: 41px;
    font-size: 32px;
    line-height: 38px;
  }

  .compare > .container {
    padding-top: 73px;
    padding-bottom: 57px;
  }

  //CALCULATOR

  .calculator > .container {
    padding-top: 45px;
    padding-bottom: 70px;
  }

  .calculator h2 {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 38px;
  }

  .calculator form .col_left {
    padding-left: 15px;
    text-align: center;
  }

  .calculator form .col_left input[type="text"] {
    margin-left: auto;
    margin-right: auto;
  }

  .calculator form .col_right {
    padding-right: 15px;
    text-align: center;
  }

  .calculator form .col_right input[type="text"]:first-of-type {
    margin-left: auto;
    margin-right: auto;
  }

  .calculator form .col_right input[type="text"]:nth-of-type(2) {
    margin-left: auto;
    margin-right: auto;
  }

  .calculator form .col_right button[type="submit"] {
    margin-left: auto;
    margin-right: auto;
  }

  .calculator form {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .calculator form .col_right .chekbox_wrap {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }

  //OUR WORK

  .our_work > .container {
    padding-top: 71px;
    padding-bottom: 50px;
  }

  .our_work h2 {
    margin-bottom: 42px;
    font-size: 32px;
    line-height: 38px;
  }

  .our_work .work_wrap {
    margin-bottom: 30px;
  }

  //PERSONAL OFFER

  .personal_offer_wrap {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  //IN PERSONAL

  .in_personal_offer .col-left h3 {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }

  .in_personal_offer form {
    background: none;
  }

  .in_personal_offer form {
    padding-top: 25px;
  }

  .personal_offer > .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  //CERTIFICATE

  .certificate > .container {
    padding-top: 73px;
    padding-bottom: 50px;
  }

  .certificate h2 {
    margin-bottom: 41px;
    font-size: 32px;
    line-height: 38px;
  }

  .certificate_wrap img {
    margin-bottom: 30px;
  }

  //PARTNERS

  .partners > .container {
    padding-top: 45px;
    padding-bottom: 0;
  }

  .partners h2 {
    margin-bottom: 17px;
    font-size: 32px;
    line-height: 38px;
  }

  .partners h5 {
    font-size: 20px;
    line-height: 24px;
  }

  .partners h5 {
    margin-bottom: 43px;
  }

  //MANUFACTURE

  .manufacture h2 {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 38px;
  }

  .manufacture h5 {
    margin-bottom: 43px;
    font-size: 20px;
    line-height: 24px;
  }

  .manufacture > .container {
    padding-top: 72px;
    padding-bottom: 80px;
  }

  .manufacture .manufacture_img_wrap img {
    max-width: 488px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .manufacture .manufacture_img_wrap .col-md-6 {
    text-align: center;
  }

  .manufacture .map_wrap .address {
    top: 60px;
    left: 100px;
  }

  .manufacture .map_wrap {
    margin-top: 50px;
  }

  //FOOTER

  footer {
    padding-bottom: 30px;
  }

  footer .phone_col p {
    padding-left: 23px;
    font-size: 18px;
    background-size: 16px;
  }

  footer .phone_col span {
    padding-left: 23px;

    &:first-of-type {
      display: none;
    }
  }

  .footer_bottom_row div:nth-of-type(1),
  .footer_bottom_row div:nth-of-type(2),
  .footer_bottom_row div:nth-of-type(3) {
    margin-bottom: 22px;
    font-size: 18px;
    text-align: center;
  }

  .footer_bottom_row div:nth-of-type(2) a {
    font-size: 18px;
  }

  footer hr:nth-of-type(2){
    display: none;
  }

  footer .footer_mail {
    padding-bottom: 0;
  }

  .footer_bottom_row {
    padding-top: 21px;
  }
}

// Экстрамалые девайсы («телефоны», < 576px)
@media (max-width: 575.98px) {

  .phone_col_wrap {
    padding-right: 80px;
  }

  .main_screen form h3 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .main_screen form p {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main_screen form p:before {
    left: 12px;
  }

  .main_screen form input[type="text"],
  .main_screen form button[type="submit"] {
    max-width: 280px;
  }

  .examples_wrap {
    padding-left: 0;
    padding-right: 0;
  }

  .headsets_types .headsets_types_wrap {
    margin-left: auto;
    margin-right: auto;
  }

  .compare ul li:before {
    display: none;
  }

  .calculator form .col_left .ui-selectmenu-button.ui-button,
  .calculator form .col_left input[type="text"],
  .calculator form .col_right button[type="submit"],
  .calculator form .col_right input[type="text"]:first-of-type,
  .calculator form .col_right input[type="text"]:last-of-type,
  .calculator form .col_right input[type="text"]:nth-of-type(2){
    max-width: 280px;
  }

  .our_work .work_desc {
    padding-left: 10px;
  }

  .our_work .work_desc div:nth-of-type(1) img {
    display: none;
  }

  .our_work .work_desc div:nth-child(odd) img {
    display: none;
  }

  .our_work .work_desc div:nth-child(even) span,
  .our_work .work_desc div:nth-of-type(9) span:last-of-type {
    padding-left: 0;
  }

  .in_personal_offer {
    padding-left: 0;
  }

  .personal_offer_wrap {
    padding-left: 10px;
    padding-right: 10px;
  }

  .in_personal_offer .col-left ul li {
    padding-left: 35px;
    padding-right: 15px;
  }

  .in_personal_offer .col-left ul li:before {
    left: 12px;
  }

  .in_personal_offer form input[type="text"],
  .in_personal_offer form button[type="submit"] {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }

  .in_personal_offer form .chekbox_wrap {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  .partners .partner {
    margin-bottom: 30px;
  }

  .manufacture .map_wrap {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .manufacture .map_wrap .address {
    top: auto;
    bottom: 10px;
  }

  .manufacture .map_wrap .address {
    left: 2px;
  }

                                        //THANKS PAGE

  .thanks > .container {
    padding-top: 152px;
    padding-bottom: 81px;
  }

  .thanks h1 {
    margin-bottom: 17px;
    font-weight: 700;
    font-size: 32px;
  }

  .thanks h2 {
    margin-bottom: 48px;
    font-size: 20px;
    line-height: 24px;
  }

  .thanks p {
    margin-bottom: 23px;
    font-size: 20px;
    line-height: 24px;
  }

                                        //PRIVACY PAGE

  .privacy {
    padding-bottom: 70px;
  }

  .privacy h1 {
    margin-bottom: 20px;
    font-size: 32px;
  }

  .privacy p {
    font-size: 14px;
    text-indent: 30px;
  }

  .privacy h2 {
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 32px;
  }

  .privacy ul li {
    font-size: 14px;
  }
}